'use client'

import { getKevelGifIdByType } from '@/app/util/kevel'
import AdsContext from '@/context/ads'
import UAParserContext from '@/context/ua-parser'
import { useContext } from 'react'
import { desktopWidth } from 'ui/src/constants'

export const DESKTOP_CONTAINER_HEIGHT = 120
export const MOBILE_CONTAINER_HEIGHT = 70
export const DESKTOP_AD_DIMENSIONS = [728, 90]
export const MOBILE_AD_DIMENSIONS = [320, 50]
export const UNIT_NAME = 'top_leaderboard'

const TopLeaderboardAd = () => {
    const { deviceType } = useContext(UAParserContext)
    const { isHTLAdEnabled, pageName } = useContext(AdsContext)
    const className = `htlad-${deviceType}_${UNIT_NAME}_${pageName}`
    const isEnabled = isHTLAdEnabled(UNIT_NAME)
    return (
        isEnabled && (
            <div className="z-stickyAd bg-giphyDarkestGrey sticky top-0 flex h-[70px] flex-wrap items-center justify-center md:h-[120px]">
                <div className="bg-giphyDarkGrey h-[50px] w-[320px] overflow-auto rounded md:h-[90px] md:w-[728px]">
                    <div className={className}></div>
                </div>
            </div>
        )
    )
}

export const KevelLeaderboardAd = () => {
    const { kevelClientData } = useContext(AdsContext)
    const { deviceType } = useContext(UAParserContext)

    const gifId = getKevelGifIdByType(kevelClientData, 'banner')
    // TODO need dimensions of ad to get aspect ratio right
    const adWidth = deviceType === 'desktop' ? desktopWidth : MOBILE_AD_DIMENSIONS[0]
    const adHeight = deviceType === 'desktop' ? DESKTOP_CONTAINER_HEIGHT : MOBILE_AD_DIMENSIONS[1]
    const aspectRatio = adWidth / adHeight
    return (
        <div className="z-stickyAd md:w-desktopWidth sticky top-0 mx-auto w-full" style={{ aspectRatio }}>
            {gifId && (
                <img
                    src={`https://media.giphy.com/media/${gifId}/giphy.webp`}
                    alt=""
                    height={adHeight}
                    width={adWidth}
                    className="h-full w-full object-cover"
                />
            )}
        </div>
    )
}

export default TopLeaderboardAd
