'use client'
import { giphyUrl } from '@/app/util/env'
import { TakeoverData } from '@/app/util/searchbar-takeover'
import { setGADataLayer } from 'analytics'
import { desktopSearchbarPadding, searchbarHeight } from './constants'

const Takeover = ({ takeover }: { takeover: TakeoverData }) => {
    const { host, pathname } = new URL(takeover.img_src)
    const src = host === 'giphy.com' ? `${giphyUrl}${pathname}` : takeover.img_src
    return (
        <a
            href={takeover.href}
            target="_blank"
            style={{ top: desktopSearchbarPadding, right: searchbarHeight }}
            className="absolute hidden cursor-pointer md:block"
            onClick={() => {
                setGADataLayer({
                    event: 'ad_banner_clicked',
                    options: {
                        banner: {
                            bannerName: 'searchbar_takeover',
                        },
                    },
                })
            }}
        >
            <img
                src={src}
                height={searchbarHeight}
                width={400}
                alt=""
                onLoad={() => {
                    setGADataLayer({
                        event: 'ad_banner_viewed',
                        options: {
                            banner: {
                                bannerName: 'searchbar_takeover',
                            },
                        },
                    })
                }}
            />
        </a>
    )
}
export default Takeover
